// 页面公用模块
import axios from 'axios'
import QS from 'qs'
// 图表数据
export const chartData = () => {
    return axios.post(`/api/dataCount/chartData`).then(res => res.data)
}

// 每日数据
export const dayData = () => {
    return axios.post(`/api/dataCount/dayData`).then(res => res.data)
}