<template>
  <div class="container">
    <el-card class="box-card">
      <div class="menu">平台概况</div>
      <div v-if="toDayData" style="display: flex">
        <div class="toj">
          <div class="chil">
            <span class="title">新增用户</span>
          </div>
          <div class="chil zri">
            <span class="number">{{ toDayData.userData.todayCount }}</span>
          </div>
          <div class="chil zri">
            <span class="small">昨日</span>
            <span>{{ toDayData.userData.yesterdayCount }}</span>
          </div>
        </div>

        <div class="toj">
          <div class="chil">
            <span class="title">新增订单</span>
          </div>
          <div class="chil zri">
            <div style="text-align: right">
              <span class="number">{{ toDayData.orderData.todayCount }}</span>
              <div class="order-info">
                <div style="color: #47a30b">
                  <span>已支付:</span>
                  <span>{{ toDayData.orderData.payCount }}</span>
                </div>
                <div style="color: #fa123d">
                  <span>未支付:</span>
                  <span>{{ toDayData.orderData.unPayCount }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="chil zri">
            <span class="small">昨日</span>
            <span>{{ toDayData.orderData.yesterdayCount }}</span>
          </div>
        </div>

        <div class="toj">
          <div class="chil">
            <span class="title">礼物</span>
          </div>
          <div class="chil zri" style="justify-content: space-between">
            <span>总计:</span>
            <span class="number"><span style="font-size: 18px">￥</span>{{ toDayData.giftMoneyData.totalMoney }}</span>
          </div>
          <div class="chil zri">
            <span class="small">昨日</span>
            <span><span>￥</span>{{ toDayData.giftMoneyData.yesterdayMoney }}</span>
          </div>
        </div>

      </div>
      <div class="menu">数据统计</div>
      <div class="chart-box">
        <div id="userChart" class="own-chart"></div>
        <div id="giftChart" class="own-chart"></div>
      </div>
      <div class="chart-box">
        <div id="orderChart" class="own-chart"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {chartData, dayData} from '@/apis/count'

export default {
  data() {
    return {
      toDayData: null,
      userChart: null,
      orderChart: null,
      giftChart: null
    };
  },
  mounted() {
    this.getToDayData();
    this.initChart();
  },
  methods: {
    async getToDayData() {
      const resp = await dayData();
      if (resp.code == 200) {
        this.toDayData = resp.data;
        console.log(this.toDayData);
      }
    },
    async initChart() {
      const resp = await chartData();
      let charts;
      if (resp.code == 200) {
        charts = resp.data;
      }
      this.userChart = echarts.init(document.getElementById('userChart'));
      this.userChart.setOption({
        title: {
          text: '用户'
        },
        tooltip: {},
        xAxis: {
          data: charts.userChart.map(x => x.date)
        },
        yAxis: {},
        series: [
          {
            name: '注册数',
            type: 'bar',
            data: charts.userChart.map(x => x.count)
          }
        ]
      });

      this.orderChart = echarts.init(document.getElementById('orderChart'));
      this.orderChart.setOption({
        title: {
          text: '订单数据统计'
        },
        tooltip: {},
        xAxis: {
          data: charts.orderChart.map(x => x.date)
        },
        yAxis: {},
        series: [
          {
            name: '数量',
            type: 'line',
            data: charts.orderChart.map(x => x.count)
          }
        ]
      });

      this.giftChart = echarts.init(document.getElementById('giftChart'));
      this.giftChart.setOption({
        title: {
          text: '礼物'
        },
        tooltip: {},
        xAxis: {
          data: charts.giftChart.map(x => x.date)
        },
        yAxis: {},
        series: [
          {
            name: '礼物金额',
            type: 'bar',
            data: charts.giftChart.map(x => x.count)
          }
        ]
      });

    }

  }
};
</script>

<style lang="less" scoped>
.menu {
  font-size: 18px;
  padding: 10px 0;
}

.toj {
  width: 200px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #8c939d;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;

  .chil {
    width: 100%;
  }

  .title {
    font-size: 14px;
  }

  .number {
    color: #000000;
    font-size: 30px;
  }

  .zri {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;

    .small {
      margin-right: 10px;
    }
  }

  .order-info {
    font-size: 12px;
    font-style: italic;

    & span {
      padding-left: 5px;
    }

  }

}


.chart-box {
  display: flex;
  height: 400px;

  .own-chart {
    flex: 1;
  }
}

</style>